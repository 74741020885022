import React from 'react'

const Input = ({uploadFiles}) => {
    return(
    <>
        <label htmlFor="file" className="btn-add-picture">
            <svg width="18" height="17" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.76167 8.25201L12.125 1.88867L18.4883 8.25201M2.125 15.5253V20.9803H22.125V15.5253H2.125ZM12.125 1.88867V15.222V1.88867Z" stroke="white" strokeWidth="2.5"/>
            </svg>
        </label>
        <input 
            type="file" 
            id="file" 
            name="video"
            multiple
            accept="video/*,image/*"
            onChange={(e) => uploadFiles(e.target.files[0])}           
        />
    </>
    )
}


export default Input