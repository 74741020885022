import React from 'react' 


const Logo = () => {
    return(
    <div className='text-center my-5'>
        <svg width="120" height="28" viewBox="0 0 269 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 49.2222H5.4187C8.91828 49.2222 10.6116 50.8136 10.6116 53.2009C10.6116 54.565 9.93429 55.7017 8.57961 56.1565C10.3858 56.4975 11.4019 57.8616 11.4019 59.5668C11.4019 62.0676 9.8214 63.8865 5.87026 63.8865H0V49.2222ZM5.19292 51.7231H3.2738V55.1334H5.19292C6.66049 55.1334 7.45072 54.565 7.45072 53.4282C7.45072 52.1778 6.66049 51.7231 5.19292 51.7231ZM5.4187 57.5206H3.16091V61.2719H5.4187C7.11205 61.2719 7.90227 60.7035 7.90227 59.4531C8.01516 58.089 7.11205 57.5206 5.4187 57.5206Z" fill="black"/>
        <path d="M13.7725 49.2222H23.481V51.8367H17.0463V54.906H22.6907V57.5206H17.0463V61.0446H23.7067V63.6591H13.8854V49.2222H13.7725Z" fill="black"/>
        <path d="M38.1567 49.2221L33.1896 57.9752V63.659H29.9158V57.9752L25.0615 49.1084H28.5611L31.6091 55.1333L34.6571 49.1084H38.1567V49.2221Z" fill="black"/>
        <path d="M45.4944 64C41.2046 64 38.6082 60.7033 38.6082 56.4973C38.6082 52.2913 41.2046 48.9946 45.4944 48.9946C49.7842 48.9946 52.3807 52.2913 52.3807 56.4973C52.3807 60.7033 49.7842 64 45.4944 64ZM45.4944 61.2717C47.6393 61.2717 48.994 59.4529 48.994 56.4973C48.994 53.5417 47.6393 51.7229 45.4944 51.7229C43.3495 51.7229 41.9948 53.5417 41.9948 56.4973C41.9948 59.4529 43.3495 61.2717 45.4944 61.2717Z" fill="black"/>
        <path d="M54.8643 49.2222H58.0252L63.7825 58.5437V49.2222H66.9435V63.7728H63.7825L58.0252 54.4513V63.7728H54.8643V49.2222Z" fill="black"/>
        <path d="M75.6361 63.7728H70.3303V49.2222H75.6361C79.926 49.2222 82.7482 52.1778 82.7482 56.4975C82.6353 60.8172 79.926 63.7728 75.6361 63.7728ZM73.6041 61.0446H75.5233C77.8939 61.0446 79.3615 59.5668 79.3615 56.4975C79.3615 53.4282 77.781 51.9504 75.5233 51.9504H73.6041V61.0446Z" fill="black"/>
        <path d="M103.181 63.7728H99.6814L98.7783 60.8172H93.4725L92.5694 63.7728H89.0698L94.1499 49.2222H98.2139L103.181 63.7728ZM94.2627 58.089H97.8752L96.069 52.2914L94.2627 58.089Z" fill="black"/>
        <path d="M104.761 57.8616V49.2222H108.035V57.9753C108.035 60.1351 108.938 61.2719 110.97 61.2719C112.89 61.2719 113.906 60.1351 113.906 57.9753V49.2222H117.179V57.8616C117.179 61.954 114.696 64.0001 110.97 64.0001C107.358 64.0001 104.761 61.954 104.761 57.8616Z" fill="black"/>
        <path d="M123.501 51.9504H119.324V49.2222H130.952V51.9504H126.775V63.7728H123.501V51.9504Z" fill="black"/>
        <path d="M139.08 64C134.79 64 132.194 60.7033 132.194 56.4973C132.194 52.2913 134.79 48.9946 139.08 48.9946C143.37 48.9946 145.966 52.2913 145.966 56.4973C145.966 60.7033 143.37 64 139.08 64ZM139.08 61.2717C141.225 61.2717 142.58 59.4529 142.58 56.4973C142.58 53.5417 141.225 51.7229 139.08 51.7229C136.935 51.7229 135.581 53.5417 135.581 56.4973C135.581 59.4529 136.935 61.2717 139.08 61.2717Z" fill="black"/>
        <path d="M155.11 61.1582L151.724 54.6787V63.7728H148.563V49.2222H151.837L156.126 57.6342L160.416 49.2222H163.69V63.7728H160.529V54.6787L157.142 61.1582H155.11Z" fill="black"/>
        <path d="M173.173 64C168.883 64 166.286 60.7033 166.286 56.4973C166.286 52.2913 168.883 48.9946 173.173 48.9946C177.462 48.9946 180.059 52.2913 180.059 56.4973C180.059 60.7033 177.462 64 173.173 64ZM173.173 61.2717C175.318 61.2717 176.672 59.4529 176.672 56.4973C176.672 53.5417 175.318 51.7229 173.173 51.7229C171.028 51.7229 169.673 53.5417 169.673 56.4973C169.673 59.4529 171.028 61.2717 173.173 61.2717Z" fill="black"/>
        <path d="M185.365 51.9504H181.188V49.2222H192.816V51.9504H188.639V63.7728H185.365V51.9504Z" fill="black"/>
        <path d="M198.347 49.2222V63.7728H195.073V49.2222H198.347Z" fill="black"/>
        <path d="M205.572 63.7728L200.492 49.2222H203.992L207.604 60.1351L211.216 49.2222H214.716L209.636 63.7728H205.572Z" fill="black"/>
        <path d="M216.861 49.2222H226.57V51.8367H220.135V54.906H225.779V57.5206H220.135V61.0446H226.683V63.6591H216.861V49.2222Z" fill="black"/>
        <path d="M6.66039 2.61475H0.112793V37.9682H6.5475V14.6645L15.6916 30.5792H20.6587L29.6899 14.6645V37.9682H36.2375V2.61475H29.6899L18.1751 23.1902L6.66039 2.61475Z" fill="black"/>
        <path d="M164.819 2.61475H158.045V37.9682H181.188V31.8297H164.819V2.61475Z" fill="black"/>
        <path d="M144.612 2.61475H137.838V38.0819H144.612V2.61475Z" fill="black"/>
        <path d="M196.654 2.61475H189.88V38.0819H196.654V2.61475Z" fill="black"/>
        <path d="M251.857 31.8297V22.8492H266.194V16.8243H251.857V8.75329H268V2.61475H245.083V37.9682H268V31.8297H251.857Z" fill="black"/>
        <path d="M234.133 8.75329V2.61475H208.733V8.75329H226.118L207.83 31.8297V37.9682H234.133V31.8297H216.184L234.133 8.75329Z" fill="black"/>
        <path d="M53.6226 19.4387C53.6226 12.5044 59.1542 6.93428 66.0405 6.93428C68.4111 6.93428 70.556 7.61634 72.4752 8.75311V1.13677C70.4431 0.454707 68.2982 0 66.0405 0C55.3159 0 46.7363 8.75311 46.7363 19.4387C46.7363 21.2575 46.9621 23.0764 47.5266 24.7815H54.8644C54.0742 23.1901 53.6226 21.3712 53.6226 19.4387Z" fill="black"/>
        <path d="M80.1516 21.1441C80.1516 28.0784 74.6201 33.6485 67.7338 33.6485C65.3631 33.6485 63.2182 32.9665 61.2991 31.8297V39.446C63.3311 40.1281 65.476 40.5828 67.7338 40.5828C78.3454 40.5828 86.925 31.8297 86.925 21.1441C86.925 19.3252 86.6992 17.5064 86.1348 15.8013H78.797C79.7001 17.3927 80.1516 19.2116 80.1516 21.1441Z" fill="black"/>
        <path d="M85.7532 8.00272L80.9189 2.5957L48.0303 32.4117L52.8645 37.8187L85.7532 8.00272Z" fill="#F45000"/>
        <path d="M120.228 19.3251C123.501 18.302 124.969 15.4601 124.969 12.1635C124.969 6.36599 121.131 2.50098 112.664 2.50098H97.9883V37.9681H113.454C122.937 37.9681 126.662 33.7621 126.662 27.5099C126.775 23.5312 124.517 20.3482 120.228 19.3251ZM104.649 8.7532H112.664C116.389 8.7532 118.308 9.88997 118.308 12.8456C118.308 15.8012 116.389 17.1653 112.664 17.1653H104.649V8.7532ZM113.341 31.8296H104.649V22.3944H113.341C117.631 22.3944 119.663 23.9859 119.663 27.1688C119.663 30.4655 117.631 31.8296 113.341 31.8296Z" fill="black"/>
        </svg>
    </div>
    )
}


export default Logo 