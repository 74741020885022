import React , {useState , useContext} from 'react';
import User from '../User';
import Miniature from '../images/Miniature';
import Input from './Input';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';
import Video from '../Video';




const Form = () => {
  
  const { user } = useContext(UserContext)
  const { handelMessage } = useContext(MessageContext)

  const [twitte , setTwitte] = useState("")

  const [showButton , setShowButton] = useState(false)
  const [picture , setPicture] = useState([])
  const [fileName, setFileName] = useState([])
  const [videoFile, setVideoFile] = useState(null)
  const [videoName, setVideoName] = useState(null)

  const handelSubmit = (e) => {
    e.preventDefault()
      
    if (twitte || fileName.length > 0 || videoName) {

      let body = new FormData()

      if (fileName.length > 0) {
          fileName.forEach(element => {
              body.append("pictures", element);
          });
      }

      body.append("message", twitte);
      body.append("firstname", user.firstname);
      body.append("code_event",  user.code_event);
      body.append("Table_users", user.code_user);

      if (videoName) {
        body.append('video' , videoName)
      }

      handelMessage(body , "POST" , null )
      resetForm()    
    }

  }

  const handelChangeTexteare = (e) => {
     if(e.target.value){
       setTwitte(e.target.value)
       setShowButton(true)
     }else{
       setTwitte("")
      setShowButton(false)
     }

   }



  const uploadFiles = (files) => {
    if (files.type.includes("video")) {
      let blobURL = URL.createObjectURL(files);
      setVideoFile(blobURL)
      
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files);
      setVideoName(files)
      
    } else {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        setPicture([...picture,fileReader.result]) 
      }

      fileReader.readAsDataURL(files);
      setFileName([...fileName,files])
    }
    setShowButton(true)
  }

  const resetForm = () =>{
    setTwitte("")
    setPicture([]) 
    setVideoFile(null)
    setVideoName(null)
    setShowButton(false)
    setFileName([])
  }


  const deleteFile = (index) => {
    let newData = picture
    newData.splice(index, 1)
    let fileData = fileName
    fileData.splice(index, 1)
    setPicture([...newData]) 
    setFileName([...fileData])
  }


  const deleteVideo = () => {
    setVideoFile(null)
    setVideoName(null)
  }

  
  return(
    <li className="list-group-item form">
        <form className="d-flex justify-content-between align-items-start" onSubmit={handelSubmit} encType="multipart/form-data">

          <User user={user}/>

          <div className="form-action col-sm-11 mb-3">

            <textarea className="input-text" placeholder="Votre message" name="text" onChange={handelChangeTexteare} value={twitte.text}></textarea> 

            <div className="d-flex justify-content-between align-items-center">

              <Input uploadFiles={uploadFiles}/>

              <button 
                type="submit" 
                className={`btn btn-tweet text-uppercase ${showButton ? "" : "disabled"}`}
              > 
                Envoyer
              </button>
          
            </div>

            {
              picture.length > 0 ?
              <div className="d-flex flex-wrap mt-2">
                <Miniature miniatures={picture} deleteFile={deleteFile}/>
              </div>
              : ""
            }
         
          {videoFile && (
            <Video
              videoFile={videoFile}
              deleteVideo={deleteVideo}
            />
          )}

          </div>

        </form>
    </li>

    )
}


export default Form