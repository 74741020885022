import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'


export default function ButtonComment({ setShowForm, showForm, id, item, currentLanguage }) {

    const handelChange = () => {
        if (showForm.status) {
            setShowForm({
                status: false,
                id : ""
            })
        } else {
            setShowForm({
                status: true,
                id : id
            })
        }
    }
    return (
        <div className="reply" onClick={handelChange}>
            <button className="button-reply button" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title={
                showForm.status ?(
                    currentLanguage === 1 ? "Annuler" : "Cancel"
                ) : (
                    currentLanguage === 1 ? "Commenter" : "Comment"  
                )
                
            }>
       
                {showForm.status && showForm.id === item.id ? (
                    <FontAwesomeIcon
                        icon={faXmark}
                        color="#f45000"
                        size="lg"
                    />
                   
                ) : (
         
                    <FontAwesomeIcon
                        icon={faComment}
                        color="#f9d2bf"
                        size="lg"
                    />
                )}

                
            </button>
            <small className="primary-color">{item.comments}</small>
        </div>
    )
}


