import React , { useState , useContext , useEffect } from 'react';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'


const ButtonLikeComment = ({ comment }) => {
    
     
    const { user} = useContext(UserContext)
    const {likeComment } = useContext(MessageContext)

    const [state , setState]  =  useState({ isChecked: false })

    const checked = () =>{
        if(state.isChecked === false ){
            setState({isChecked: true})
            likeComment(comment.id , "add")
        }else{
            setState({isChecked: false})
            likeComment(comment.id , "remove")
        }
    }

    useEffect(() => {
        if (comment.users) {
            comment.users.forEach(element => {
                if (Number(element.code_user) === user.code_user) {
                     setState({isChecked: true})
                }
            });
        }
    }, [user , comment])

    return(
    <div className="like-comment">
        <button className="button-like button" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title=
                {
                state.isChecked  === true ? (
                    user.currentLanguage === 1  ? "je n'aime plus" : "I no longer love"
                ) : (
                   user.currentLanguage === 1 ? "j'aime"   : "I like"  
                )
                    
                }
                onClick={() => checked()}
            >

                <FontAwesomeIcon
                    icon={faHeart}
                    className={`${state.isChecked ===  true ? "red-svg" : "svg-like"}`}
                    size="sm"
                /> 

        </button>
        &ensp;
        <small className={`${state.isChecked ===  true  ? "red" :"primary-color"}`}>{comment.likes}</small>
    </div>
    )
}


export default ButtonLikeComment