import User from '../User';
import React , { useState , useContext} from 'react';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';


const FormComment = ({item , setShowForm}) => {

 
  const { user } = useContext(UserContext)
  const { addComment } = useContext(MessageContext)


  const [twitte , setTwitte] = useState({
    text: '',
    isNotBlank: false
  })


  const handelSubmit = (e) => {
    e.preventDefault();

          
    let body = new URLSearchParams()
    body.append("identifiant", `@${user.firstname}`)
    body.append("user_id", user.code_user)
    body.append("message", twitte.text)

    addComment(item , body)

    resetForm()
  }



  const handelChangeTexteare = (e) => {
    if(e.target.value){
      setTwitte({
        text: e.target.value,
        isNotBlank: true
      })
    }else{
      setTwitte({
        text: '',
        isNotBlank: false
      })
    }

  }



  const resetForm = () =>{
    setTwitte({
      text: '',
      isNotBlank: false
    })
    setShowForm(false)
  }


  return(
    <div className="list-group-item form mt-3">

        <form className="d-flex justify-content-between align-items-start" onSubmit={handelSubmit}>
            <User user={user.currentUser }/>

            <div className="form-action col-sm-11 mb-3">

              <textarea className="input-text"
                placeholder="Votre commentaire"
                name="text"
                onChange={handelChangeTexteare}
                value={twitte.text}
              >
                
              </textarea>

              <button 
                type="submit" 
                className={`btn btn-tweet text-uppercase ${twitte.isNotBlank ? "" : "disabled"}`}>
                  {/* {user.currentLanguage === 1 ? "Répondre" : "To respond"} */}
                  Répondre
              </button>
          
            </div>

        </form>
      
    </div>

    )
}


export default FormComment