import React from 'react';


const ViewBox = ({viewBox}) =>{
    return(
        <img src={viewBox} className="img-slider" alt=""/>
    )
    
}

export default ViewBox