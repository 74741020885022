import React , { useState , useContext , useEffect } from 'react';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const ButtonLike = ({ item }) => {
    
     
    const { user } = useContext(UserContext)
    const {like } = useContext(MessageContext)

    const [state , setState]  =  useState(false)

    const checked = () => {
        if(state === false ){
            setState(true)
            like(item.id , "add")
        }else{
            setState(false)
            like(item.id , "remove")
        }
    }

    useEffect(() => {
        if (item.users) {
            item.users.forEach(element => {
                if (Number(element.code_user) === user.code_user) {
                    setState(true)
                }
            })
        }
    }, [user , item])
  


    return(
    <div className="like">
        <button className="button-like button" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title={
            state === true ? (
                user.currentLanguage === 1  ? "je n'aime plus" : "I no longer love"
            ) : (
                user.currentLanguage === 1 ? "j'aime"   : "I like"  
            )
                        
            }
            onClick={() => checked()}
        >
            <FontAwesomeIcon
                icon={faHeart}
                className={`${state ? "red-svg" : "svg-like"}`}
                size="lg"
            /> 
        </button>
   
        <small className={state ? "red" : "primary-color"}>{item.likes}</small>
    </div>
    )
}


export default ButtonLike





