import React from 'react';



const Image = ({ item, handelClick }) => {
    return item.pictures.map((element, index) => {
      
        return(
            <div className="img-modal-button col-sm-6" data-bs-toggle="modal" data-bs-target={`#twitte-${item.id}`}  key={index} onClick={(e) => handelClick(e)}>
                <img src={element.picture} className="img-slider" key={index} alt=""/>
            </div>
        )
    })
}


export default Image