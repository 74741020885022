import React from  'react';
import List from './components/List';
import Logout from './components/Logout';
import Logo from './components/images/Logo';



const Home = ()  =>{

  return (
    <>
      <Logo />
      <div className="mx-auto" style={{ maxWidth: '600px' }}>
        <Logout />
        <List  />
      </div>
    </>
  );
}

export default Home;
