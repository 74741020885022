import React , { useState , useContext} from 'react';
import ButtonDelete from './buttons/ButtonDelete';
import ButtonLike from './buttons/ButtonLike';
// import ButtonReply from './buttons/ButtonReply';
import Image from './images/Image';
import Slider from './images/Slider';
import TextFormat from './TextFormat';
import ButtonComment from './buttons/ButtonComment';
import FormComment from './form/FormComment';
import ListComment from './ListComment';
import MessageContext from './context/MessageContext';
import Form from './form/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'




const List = () => {
  const { messageError , loading , list } = useContext(MessageContext)


  const [showForm, setShowForm] = useState({
    status: false,
    id : ""
  })

  const [state , setState] =  useState('')
  
  const handelClick = e => { setState(e.target.getAttribute('src')) }

  const newViewBox = newImage => {
    if(newImage){
      setState(newImage)
    }
  
  }







  
  if (loading || messageError) {
    return (
      <div className="list-group-item text-center">
        <div className="spinner-grow color-spinner" role="status"></div>
        {messageError ? (
          <p>{messageError}</p>
        ) : (
          <p>Chargement en cours ...</p> 
        )}
      </div>
    )
  } 


  return (

    <ul  className="list-group">
      <Form /> 
      <li className="d-flex flex-column-reverse">
        {list.map((item, index) => {
          return (

            <div className="list-group-item bg-list" key={index}>
              {/* {item.message ? 
                <small className="green my-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-reply-all-fill green-svg" viewBox="0 0 16 16" >
                    <path d="M8.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L8.079 4.1A.716.716 0 0 0 7 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z"/>
                    <path d="M10.868 4.293a.5.5 0 0 0 .106.7l4.012 2.953a.51.51 0 0 0 .042.028.147.147 0 0 1 0 .252.512.512 0 0 0-.042.028l-4.012 2.954a.5.5 0 1 0 .593.805l3.994-2.94a1.147 1.147 0 0 0 0-1.946l-3.994-2.94a.5.5 0 0 0-.699.106z"/>
                  </svg>
                  &ensp;
                  {item.message}
                </small> 
                : ""
              } */}
          
              <div className='d-flex align-items-center'>
                
            
                {item.picture ? (
      
                  <div className="img-user" style={{ backgroundImage: `url(${item.picture})` }}></div>

                ) : (
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      color="#CCCCCC"
                      size='xl'
                    />
                )}
                    
            
                <div className="user-twitte">
                  <p className="user-pseudo">{item.firstname}&thinsp;{item.lastname}</p>
                  <p className="user-function">{item.FUNCTION}</p>
                  {/* <p className="user-link">&ensp;{item.identifiant}</p> */}
                  {/* <p className="date">&ensp;- {dateMessage.toLocaleTimeString('fr-FR', options)}</p> */}
                </div>
              </div>

              <div className="content-twitte">
            
                {item.message && (
                  <TextFormat text={item.message} />
                )}
              
                {item.video && (
                  <video src={`${item.video}?#t=1`} controls></video> 
                )}
              
                { item.pictures && item.pictures.length > 0 &&(
                  <div className='my-2'>
                    <div className="d-flex flex-wrap">
                      <Image item={item} handelClick={handelClick} />
                    </div>
                    <Slider item={item} viewBox={state} newViewBox={newViewBox} />
                  </div>
                )}
              </div>

                
            
              <div className="d-flex  align-items-center justify-content-between">

                <div className='d-flex'>
                  <ButtonLike item={item} />

                  <ButtonComment setShowForm={setShowForm} showForm={showForm} id={item.id} item={item} />
                  
                </div>
          
                <div className='container-button-delete'>
                  <ButtonDelete item={item} />
                </div>
            
              
              </div>

          
              
              {showForm.status && showForm.id === item.id && (
                <FormComment item={item} setShowForm={setShowForm} />
              )}

              <ListComment item={item} />
            
            </div>
          
          )
        })
        }
      </li>
        
    </ul>

  )



}


export default List;