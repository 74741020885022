import React, { useContext } from 'react'
import UserContext from './context/UserContext'

export default function Logout() {


    const {deconnect} = useContext(UserContext)


    return (
      <div className='logout-container'>
        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={deconnect} className="cursor">
        <path d="M13 25.5C6.09625 25.5 0.5 19.9038 0.5 13C0.5 6.09625 6.09625 0.500004 13 0.500004C14.9408 0.498545 16.8552 0.949719 18.5912 1.81768C20.3271 2.68564 21.8367 3.94647 23 5.5H19.6125C18.169 4.2272 16.389 3.39795 14.4859 3.11175C12.5828 2.82556 10.6376 3.09457 8.88361 3.88652C7.12964 4.67847 5.64144 5.9597 4.59759 7.57649C3.55374 9.19327 2.99859 11.0769 2.99875 13.0014C2.99891 14.9259 3.55437 16.8094 4.59849 18.426C5.64261 20.0426 7.13102 21.3236 8.88513 22.1153C10.6392 22.9069 12.5845 23.1756 14.4875 22.8891C16.3906 22.6026 18.1705 21.773 19.6138 20.5H23.0012C21.8378 22.0537 20.328 23.3146 18.5919 24.1826C16.8557 25.0506 14.941 25.5017 13 25.5V25.5ZM21.75 18V14.25H11.75V11.75H21.75V8L28 13L21.75 18Z" fill="#F24E1E"/>
        </svg>
      </div>


  )
}
