import React  , { useContext} from 'react';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'


const ButtonDeleteComment = ({ comment }) => {
  
  const { user} = useContext(UserContext)
  const {removeComment} = useContext(MessageContext)

    return(
    <div className="remove">
        <button className="button-remove-twitte button" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom" title={user.currentLanguage === 1 ? "retirer" : "remove"} onClick={() => removeComment(comment.id)}>
          <FontAwesomeIcon
            icon={faTrashCan}
            color="#f45000"
            size='sm'
          />
      </button>
    </div>  
    )

}



export default ButtonDeleteComment