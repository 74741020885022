import React  from 'react';
import ViewBox from './ViewBox';
import IconePrev from './IconePrev';
import IconeNext from './IconeNext';




const Slider = ({item , viewBox , newViewBox}) =>{


    const getPrevPicture = () => {

       for(let i = 0 ; i < item.pictures.length ; i ++){
           if(item.pictures[i].picture === viewBox && item.pictures[i-1]){
               newViewBox(item.pictures[i - 1].picture)
           }
       }
    }

    const getNextPicture = () =>{
        for (let i = 0; i < item.pictures.length; i++){
            if (item.pictures[i].picture === viewBox && item.pictures[i+1]) {
                newViewBox(item.pictures[i + 1].picture)
            }
        }
    }


    return(
    <div className="modal fade" id={`twitte-${item.id}`} tabIndex="-1" aria-labelledby={`modal-${item.id}`} aria-hidden="true">
    <div className="modal-dialog modal-lg">
        <div className="modal-content">
        <div className="modal-body">
            <IconePrev getPrevPicture={getPrevPicture} />
            <ViewBox viewBox={viewBox}/>
            <IconeNext getNextPicture={getNextPicture} />
        </div>
        </div>
    </div>
    </div>
    )
}


export default Slider