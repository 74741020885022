import React from 'react'

export default function Video({videoFile , deleteVideo}) {
    return (
    <div className='video-container'>
        <video src={videoFile} controls></video>  
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={deleteVideo} className="video-close">
            <path d="M0.718774 0.718774C1.07039 0.367266 1.54722 0.1698 2.0444 0.1698C2.54158 0.1698 3.01841 0.367266 3.37002 0.718774L10 7.34877L16.63 0.718774C16.9837 0.377228 17.4573 0.188238 17.9489 0.192511C18.4405 0.196783 18.9108 0.393974 19.2584 0.741615C19.6061 1.08925 19.8033 1.55953 19.8075 2.05115C19.8118 2.54277 19.6228 3.0164 19.2813 3.37002L12.6513 10L19.2813 16.63C19.6228 16.9837 19.8118 17.4573 19.8075 17.9489C19.8033 18.4405 19.6061 18.9108 19.2584 19.2584C18.9108 19.6061 18.4405 19.8033 17.9489 19.8075C17.4573 19.8118 16.9837 19.6228 16.63 19.2813L10 12.6513L3.37002 19.2813C3.0164 19.6228 2.54277 19.8118 2.05115 19.8075C1.55953 19.8033 1.08925 19.6061 0.741615 19.2584C0.393974 18.9108 0.196783 18.4405 0.192511 17.9489C0.188238 17.4573 0.377228 16.9837 0.718774 16.63L7.34877 10L0.718774 3.37002C0.367266 3.01841 0.1698 2.54158 0.1698 2.0444C0.1698 1.54722 0.367266 1.07039 0.718774 0.718774V0.718774Z" fill="#F24E1E"/>
        </svg>
    </div>
   
  )
}
