import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'


const User = ({user}) => {
  if(user){
    return(
      <div className="col-sm-1">
        {user.picture ? (
          <div className="img-user" style={{backgroundImage : `url(${user.picture})`}}></div>
        ) : 
          <FontAwesomeIcon
            icon={faCircleUser}
            color="#CCCCCC"
            size='2xl'
          />
        }
      </div> 
    )
  }else{
    return null
  }

}

export default User