import './App.css';
import React, { useContext} from  'react';
import UserContext from './components/context/UserContext'
import Login from './Login';
import Home from './Home';

const App = ()  =>{

  const { user } = useContext(UserContext)

  return (
    <>
      {user.isConnected ? (
        <Home />
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
