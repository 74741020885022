import React from 'react';

const TextFormat = ({ text }) => {
    const format = (text) =>{
        let hashtag = text.replace(/#(\w+)/g, '<strong class="color-hashtag">#$1</strong>');
        return {__html : hashtag}
    }

    return(
        <p dangerouslySetInnerHTML={format(text)} />
    )
}


export default TextFormat