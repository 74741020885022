import React  , { useContext} from 'react';
import UserContext from '../context/UserContext';
import MessageContext from '../context/MessageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'





const ButtonDelete = ({ item , currentLanguage}) => {
  
  const { user } = useContext(UserContext)
  const {handelMessage} = useContext(MessageContext)

    if( user && user.code_user === item.code_user){
          return(
          <div className="remove">
            <button className="button-remove-twitte button" type="button" data-bs-toggle="tooltip" data-bs-placement="bottom"
                title={currentLanguage === 1 ? "supprimer" : "delete"}
                onClick={() => handelMessage("", "DELETE", item.id)}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  color="#f45000"
                />
            </button>
          </div>  
          )
        
    }else{
        return null
    }
}



export default ButtonDelete