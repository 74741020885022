import { createContext, useState, useEffect, useContext } from "react"
import UserContext from "./UserContext";
import { io } from "socket.io-client";


//const socket = io(process.env.REACT_APP_URL_WEB_SOCKET, {  reconnectionDelayMax: 10000 } );
const socket = io('https://gmp5.newtelapps.fr', { path: "/api_feed/socket.io/"} , {reconnectionDelayMax: 10000 , secure: true})

const MessageContext = createContext()




export const MessageProvider = (props) => {

    const [message,setMessage] = useState({
        list: [],
        listComment : [],
        loading: true,
        messageError: ""
    })
  
    const {user} = useContext(UserContext)


    const useFetch = (code_event) => {
       
        useEffect(() => {
        (async () => {
            try {
                const data = await getListOfMessage(code_event)
                const dataComment = await getListOfComment(code_event)

                if (data.status !== 500 &&  dataComment.status !== 500) {
                    const list = await data.json()
                    const comments = await dataComment.json()
                    setMessage({
                            list: list.data ? list.data : [] ,
                            listComment: comments.data ? comments.data : [],
                            loading: false,
                            messageError : ""
                    })
                    
                    socket.emit('user-connected',code_event)

                }
            
            } catch (e) {
                setMessage({
                    ...message,
                    loading: false,
                    messageError: "Veuillez patienter ..."
                })
            }
        })()
        }, [code_event])
    
        return [
            message.list,
            message.loading,
            message.messageError,
            message.listComment
        ]
    }







    /**
     * 
     * @returns list of message
     */
    const getListOfMessage = async (codeEvent) => {
        if (codeEvent) {
            let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
            let requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers
            };
            return await fetch(`${process.env.REACT_APP_URL_API}/messages/${codeEvent}`, requestOptions)
        }
    }



    /**
     * 
     * @returns list of user
     */
    const getListOfComment = async (codeEvent) => {
        if (codeEvent) {
            let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
            let requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers
            };
            return await fetch(`${process.env.REACT_APP_URL_API}/comments/${codeEvent}`, requestOptions)
        }
    }



    /**
     * add /remove new message
     * @param {Object} body
     */
    const handelMessage = async (body , methode , message_id) => {

        setMessage({
            ...message,
            loading: true,
        })
        let requestOptions = ""
        let URL = ""

            let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
     
        if (methode === "POST") {
   
            requestOptions = {
                method: methode,
                body: body,
                headers
            }

            URL = `${process.env.REACT_APP_URL_API}/add-message-aws/${user.code_event}/${user.code_user}`

        } else {

            requestOptions = { method: methode , headers}
            URL = `${process.env.REACT_APP_URL_API}/remove-message-aws/${user.code_event}/${message_id}`
        }

        const req = await fetch(URL, requestOptions)
        
        const json = await req.json()

        if (req.status === 201) {
            if (methode === "POST") {
                const messageData = await getListOfMessage(user.code_event)
                const messages = await messageData.json()
                socket.emit('new-message' , messages.data , user.code_event)
            } else {
                socket.emit('remove-message' , json.id , user.code_event)
            }
        
        } else {
            setMessage({
                ...message,
                loading: false,
                message : json.message
            })
        }
 
    }




    /**
     * like / remove like message
     * @param {*} id 
     * @param {*} action 
     */
    const like = async (id, action) => {
        
             let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
            let requestOptions = {
                method: "PUT",
                headers
            }
    
        let URL = action === "add" ? `${process.env.REACT_APP_URL_API}/message/add/like/${id}/${user.code_user}` : `${process.env.REACT_APP_URL_API}/message/remove/like/${id}/${user.code_user}` 
        const req = await fetch(URL , requestOptions)
        
        const json = await req.json()
        
        if (req.status === 201) {

            const dataMessage = await getListOfMessage(user.code_event)
            const messages = await dataMessage.json()
            if (action === "add") {
                socket.emit('like-message' , messages.data , user.code_event)
            } else {
                socket.emit('dislike-message' , messages.data , user.code_event)
            }
           
       
        } else {
            setMessage({
                ...message,
                message : json.message
            })
        }
        
    }



    /**
     * add a comment
     * @param {*} message 
     * @param {*} body 
     */
    const addComment = async (message, body ) =>{
       
        let headers = new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
            'api_key': process.env.REACT_APP_URL_API_KEY
        })

        let requestOptions = {
            method: "POST",
            body: body,
            headers,
        }

        const req = await fetch(`${process.env.REACT_APP_URL_API}/add-comment/${message.id}/${user.code_user}`, requestOptions)
        const json = await req.json()
        if (req.status === 201) {

            const dataComment = await getListOfComment(user.code_event)
            const comments = await dataComment.json()

            const dataMessage = await getListOfMessage(user.code_event)
            const messages = await dataMessage.json()
    
            socket.emit('new-comment', comments.data , user.code_event)
            socket.emit('like-message' , messages.data , user.code_event)
        
        } else {
            setMessage({
                ...message,
                message : json.message
            })
        }
    }




    const removeComment = async (id) => {

            let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
            let requestOptions = {
                method: "DELETE",
                headers
            }

        const req = await fetch(`${process.env.REACT_APP_URL_API}/remove-comment/${id}`,requestOptions)
        const json = await req.json()

        if (req.status === 201) {
            const dataMessage = await getListOfMessage(user.code_event)
            const messages = await dataMessage.json()

            socket.emit('remove-comment', json.id , user.code_event)
            socket.emit('like-message' , messages.data , user.code_event)
        } else {
          
            setMessage({
                ...message,
                message : json.message
            })
        }
    }

    
  


    /**
     * like / remove like message
     * @param {*} id 
     * @param {*} action 
     */
    const likeComment = async (id, action ) => {
        
            let headers = new Headers({
                'Access-Control-Allow-Origin': '*',
                'api_key': process.env.REACT_APP_URL_API_KEY
            })
            let requestOptions = {
                method: "PUT",
                headers
            }
    
        let URL = action === "add" ? `${process.env.REACT_APP_URL_API}/comment/add/like/${id}/${user.code_user}` : `${process.env.REACT_APP_URL_API}/comment/remove/like/${id}/${user.code_user}` 
        const req = await fetch(URL , requestOptions)
        
        const json = await req.json()
        
        if (req.status === 201) {
            if (action === "add") {

                const commentData = await getListOfComment(user.code_event)
                const comments = await commentData.json()
                socket.emit('like-comment', comments.data , user.code_event)
                
            } else {
                const commentData = await getListOfComment(user.code_event)
                const comments = await commentData.json()
                socket.emit('dislike-comment' , comments.data , user.code_event)
            }
           
        } else {
            setMessage({
                ...message,
                message : json.message
            })
        }
        
    }



    
    
    /**
     * Re-partager un message en attente
     * @param {*} message 
     */
    const reply = (message) => {
       
        let now = new Date()

        const tab = message.list.map((element) =>{
            if(element.id === message.id){
                element.reply = element.reply + 1
            }
            return element
        })

        let newMessage = {
            picture: message.picture,
            first: message.first,
            last: message.last,
            text: message.text,
            date : now.toLocaleString('fr-FR' , {dateStyle: 'medium' , timeStyle : 'short'}),
            like: 0,
            reply: 0,
            pictures: message.pictures,
            checkDefault: false,
            message: `you retweeted @${message.first}`
        }
        tab.push(newMessage)
        setMessage({
            ...message,
            list :  tab
        })

    }


    /**
     * @param {object} message 
     */
    const removeReply = (message) =>{
        const tab = message.list.map((element) =>{
        if(element.id === message.id){
            element.reply = element.reply - 1
            element.message = ""
        }
        return element
        })
        setMessage({
            ...message,
            list :  tab
        })
    }



    /***SOCKET**/


    socket.on('new-message', function (messages) {
        console.log("ici new message")
        setMessage({
             ...message,
            list: messages,
            loading: false
        })
    })


    socket.on('remove-message', function (id) {
        const messageList = [...message.list]
    
        let index = ""
        messageList.forEach(element => {
            if (element.id === Number(id) ) {
                index = messageList.indexOf(element)
            }
        });
 
        messageList.splice(index, 1)
      
        setMessage({
            ...message,
            list: [...messageList],
            loading: false,
        })
    })




    socket.on('like-message', function (messages) {
        setMessage({
            ...message,
            list : messages
        })
    })


    socket.on('dislike-message', function (messages) {
        setMessage({
            ...message,
            list : messages
        })
    })


    /******/

    socket.on('new-comment', function (listComment) {
        setMessage({
            ...message,
            listComment: listComment
        })
    })


    socket.on('remove-comment', function (id) {
        const commentList = [...message.listComment]

        let index = ""
        commentList.forEach(element => {
            if (element.id === Number(id)) {
                index = commentList.indexOf(element)
            }
        });
      
        commentList.splice(index, 1)
      
        setMessage({
            ...message,
            listComment : [...commentList]
        })
    })


    

    socket.on('like-comment', function (comments) {
        setMessage({
            ...message,
            listComment : comments
        })
    })


    socket.on('dislike-comment', function (comments) {
        setMessage({
            ...message,
            listComment : comments
        })
    })


    const [ list ,  loading , messageError , listComment  ] = useFetch(user.code_event)

    return (
        <MessageContext.Provider value={{  list ,  loading , messageError,  listComment, handelMessage , like , reply , removeReply , addComment , removeComment , likeComment }}>
            {props.children}
        </MessageContext.Provider>
    )

}

export default  MessageContext