import { createContext, useState, useEffect } from "react"


const UserContext = createContext()




export const UserProvider = (props) => {


    const [user,setUser] = useState({
        currentLanguage: 1,
        code_event: "",
        code_user:"",
        email: "",
        firstname: "",
        lastname: "",
        picture :"",
        isConnected : false
    })
  



    useEffect(() =>{
        userAuthentification()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



     /**
     * @param {String} url
     */
    const connect = () => {
        if (localStorage.getItem("userInfo")) {
            let data = JSON.parse(localStorage.getItem("userInfo"))
            setUser({
                ...user,
                isConnected: true,
                code_event:  data.CODE_EVENT,
                code_user: data.id,
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                picture : data.picture,
            })
        }
    }




    const deconnect = () => {
        localStorage.clear()
        setUser({
            isConnected: false,
            code_event:  "",
            code_user: "",
            email: "",
            firstname: "",
            lastname: "",
            picture : "",
        });
    };



    const userAuthentification = async () =>{
        const data = await JSON.parse(localStorage.getItem("userInfo"))
        if(data){
            setUser({
                ...user,
                isConnected: true,
                code_event:  data.CODE_EVENT,
                code_user: data.id,
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                picture : data.picture,
            })
        }else{
            deconnect()
        }
    }
 


    return (
        <UserContext.Provider value={{ connect , deconnect  , user }}>
            {props.children}
        </UserContext.Provider>
    )

}

export default UserContext